.header {
  position: fixed;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 100;
  background-color: var(--color-background);
  &.scrolled {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 992px) {
    display: none;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 30px;
  }
}

.burger-container {
  display: none;

  @media (max-width: 992px) {
    position: fixed;
    display: block;
    top: 45px;
    right: 25px;
    left: auto;
    z-index: 100;
  }
}
