.contacts {
  padding-bottom: 200px;
  @media (max-width: 576px) {
    padding-bottom: 50px;
  }
  &__title {
    margin-bottom: 30px;
  }

  &__container {
    position: relative;
    display: flex;
  }

  &__left {
    width: 810px;
    padding: 40px;
    display: flex;
    gap: 55px;
    border-radius: 15px;
    background-color: var(--color-background-gray);
    @media (max-width: 576px) {
      flex-direction: column;
      gap: 30px;
    }
  }

  &__item {
    &-title {
      font-size: 18px;
      font-weight: 700;
      color: var(--color-black);
    }

    &-subtitle {
      font-size: 18px;
      font-weight: 400;
      color: var(--color-black);
    }

    &-link {
      font-size: 18px;
      font-weight: 400;
      color: var(--color-primary);
    }
  }

  &__right {
    position: absolute;
    top: 0;
    right: 0;
    width: 387px;
    height: 100%;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-image: linear-gradient(324deg, rgba(115, 0, 161, 1) 0%, rgba(89, 0, 255, 1) 100%);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    @media (max-width: 576px) {
      display: none;
    }

    &-img {
      width: 110px;
      height: 110px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-text {
      max-width: 160px;
      font-size: 18px;
      font-weight: 400;
      color: var(--color-white);
      opacity: 0.85;
    }
  }
}
