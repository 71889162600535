.slider {
  position: relative;
  width: 100%;
  margin: 0 auto;

  &__item {
    text-align: center;
    max-width: 760px;

    &-text {
      margin-bottom: 36px;
      font-size: 22px;
      line-height: 1.55;
      font-weight: 300;
      color: var(--color-text);
      @media (max-width: (576px)) {
        font-size: 18px;
        line-height: 1.25;
      }
    }

    &-img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    &-name {
      margin-top: 14px;
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 600;
      color: var(--color-text);
    }

    &-position {
      font-size: 14px;
      font-weight: 300;
      color: var(--color-text);
      span {
        color: var(--color-text--highlighted);
      }
    }
  }
}

// .slider {
//     position: relative;
//     width: 100%;
//     margin: auto;

//     &__item {
//       width: 300px;
//       display: flex;
//       align-items: center;
//       flex-direction: column;
//       &-img {
//         height: 180px;
//         width: 180px;
//       }
//       &-name {
//         margin-top: 20px;
//         font-size: 21px;
//         font-weight: 700;
//         line-height: 24px;
//         color: var(--color-text);
//       }
//       &-position {
//         margin-top: 5px;
//         font-size: 14px;
//         font-weight: 300;
//         line-height: 20px;
//         color: var(--color-text);
//       }
//     }
//   }

//   .swiper {
//     max-width: 1000px;
//   }

.swiper-slide {
  display: flex;
  justify-content: center;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-gray);
  border-radius: 50px;
  transition: all 0.3s ease;
}

.swiper-button-prev {
  left: 0px;
}

.swiper-button-next {
  right: 0px;
}

.swiper-button-disabled {
  opacity: 0.4;
}

.swiper-button-icon {
  font-size: 24px;
  color: var(--color-black);
}

.swiper-wrapper {
  padding-bottom: 40px;
}
