.burger-button {
  width: 58px;
  height: 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: var(--color-black);
  border-radius: 100%;
  cursor: pointer;
  span {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 10px;
    background-color: #dbdbdb;
    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}
