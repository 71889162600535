.accordion {
  display: flex;
  flex-direction: column;
  gap: 15px;

  &__item {
    padding: 20px 20px;
    background-color: var(--color-accordion-bg);
    border-radius: 15px;
    text-align: left;
  }

  &__title {
    position: relative;
    font-size: 18px;
    font-weight: 500;
    color: var(--color-black);
    cursor: pointer;
    width: 100%;
    text-align: left;
  }

  &__collapse {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s;
  }

  &__text {
    padding-top: 15px;
    font-size: 16px;
    font-weight: 300;
  }

  &__icon {
    position: absolute;
    display: block;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    transition: all 0.3s;
    font-size: 20px;
    color: var(--color-primary);
  }
}

.accordion__collapse.open {
  // height: auto; // в этом случае не будет работать плавная анимация
  max-height: 200px;
}

.accordion__icon.active {
  transform: translateY(-50%) rotate(-180deg);
}
