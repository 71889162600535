.cases {
  padding-top: 100px;
  @media (max-width: (576px)) {
    padding-top: 50px;
    text-align: center;
  }

  &__founder {
    padding-top: 45px;
    padding-bottom: 45px;
    padding-left: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 35px;
    @media (max-width: (576px)) {
      flex-direction: column;
      padding-left: 20px;
    }

    &-img {
      height: 150px;
      width: 150px;
      object-fit: cover;
      border-radius: 50%;
      @media (max-width: (576px)) {
        height: 100px;
        width: 100px;
      }
    }

    &-wrapper {
      position: relative;
      padding: 30px 35px;
      border-radius: 15px;
      background-color: var(--color-background-gray);
      &::before {
        content: '';
        position: absolute;
        left: -25px;
        top: 30%;
        width: 0;
        height: 0;
        border-top: 25px solid #f6f6f6;
        border-left: 25px solid transparent;
        @media (max-width: (576px)) {
          top: -24px;
          left: 60px;
          transform: rotate(180deg);
        }
      }
    }

    &-name {
      font-size: 18px;
      font-weight: 700;
      color: var(--color-text);
    }

    &-position {
      font-size: 18px;
      font-weight: 400;
      color: var(--color-text-gray);
    }

    &-text {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 300;
      color: var(--color-text);
    }
  }

  &__item {
    margin-top: 20px;
    position: relative;
    height: 400px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 15px;
    background-image: linear-gradient(61deg, rgba(87, 25, 153, 1) 0%, rgba(130, 69, 198, 1) 69%);
    z-index: 1;
    @media (max-width: 576px) {
      overflow: hidden;
    }

    &-container {
      display: flex;
      gap: 40px;
      width: 100%;
      height: 100%;
      @media (max-width: 576px) {
        flex-direction: column;
      }
    }

    &-wrapper {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 576px) {
        padding-top: 20px;
      }
    }

    &-title {
      font-size: 35px;
      font-weight: 700;
      color: var(--color-white);
    }

    &-descr {
      color: var(--color-white);
      font-size: 18px;
      line-height: 1.25;
      font-weight: 400;
      opacity: 0.75;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    &-imgs {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      @media (max-width: 576px) {
        display: none;
      }
    }

    &-img {
      &--1 {
        position: absolute;
        width: 234px;
        height: 462px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        @media (max-width: 576px) {
          height: 300px;
          width: 180px;
          top: 100%;
          transform: translate(10%, -60%);
        }
      }

      &--2 {
        position: absolute;
        top: -45%;
        width: 169px;
        height: 367px;
        left: 270px;
        // left: 160px;
      }

      &--3 {
        position: absolute;
        bottom: -45%;
        width: 169px;
        height: 367px;
        left: 270px;
      }

      &--4 {
        position: absolute;
        width: 169px;
        height: 367px;
        top: -65%;
        right: 25px;
      }

      &--5 {
        position: absolute;
        width: 169px;
        height: 367px;
        right: 25px;
        bottom: -25%;
      }
    }
  }
}

.cases__item-container.reverse {
  flex-direction: row-reverse;
  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.cases__item-img--1.left {
  left: 150px;
  @media (max-width: 576px) {
    left: 50%;
  }
}
