.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: -100%;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
  transition: all 0.3s;

  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 98;
    opacity: 0;
    transition: all 0.2s;
  }

  &__content {
    max-width: 300px;
    height: 100vh;
    padding: 48px 40px 38px;
    background-color: var(--color-white);
    z-index: 99;
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.mobile-menu.active {
  right: 0;

  .mobile-menu__overlay {
    opacity: 1;
    display: block;
  }
}
