.promo {
  padding-top: 140px;
  padding-bottom: 100px;
  background-color: var(--color-background);
  overflow: hidden;
  @media (max-width: (576px)) {
    padding-top: 20px;
    padding-bottom: 0;
  }
  &__container {
    position: relative;
    @media (max-width: (576px)) {
      text-align: center;
    }
  }

  &__img {
    position: absolute;
    top: 80px;
    right: 0;
    height: 655px;
    @media (max-width: (576px)) {
      position: static;
      height: 355px;
      overflow: hidden;
    }
    img {
      height: 100%;
      @media (max-width: (576px)) {
        height: 500px;
      }
    }
  }

  &__title {
    max-width: 765px; // временно для img
    font-size: 64px;
    font-weight: 700;
    line-height: 1.1;
    color: var(--color-text);
    @media (max-width: (576px)) {
      font-size: 34px;
    }
  }

  &__icons {
    display: flex;
    gap: 30px;
    margin-top: 40px;
    margin-bottom: 60px;
  }

  &__icon {
    max-width: 185px;
    &-img {
      width: 35px;
    }
    &-descr {
      margin-top: 10px;
      font-size: 18px;
      line-height: 1.25;
      font-weight: 600;
      color: var(--color-text);
    }
  }
}
