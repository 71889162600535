.language-toggle {
  display: flex;
  gap: 10px;
  @media (max-width: '992px') {
    margin-top: 15px;
  }

  &__item {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-text-light);
  }
}
