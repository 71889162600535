.reviews {
  padding-top: 100px;
  @media (max-width: (576px)) {
    padding-top: 50px;
  }

  &__title {
    margin-bottom: 50px;
    @media (max-width: (576px)) {
      text-align: center;
      margin-bottom: 30px;
    }
  }

  &__slider {
    text-align: center;
  }
}
