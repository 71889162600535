.btn {
  border-radius: 30px;
  font-weight: 600;
  padding-left: 34px;
  padding-right: 34px;
  &:hover {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }
}

.btn.primary {
  height: 40px;
  background-color: var(--color-primary);
  font-size: 14px;
  color: var(--color-white);
  &:hover {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }
}

.btn.primary-big {
  height: 60px;
  padding-left: 58px;
  padding-right: 58px;
  background-color: var(--color-primary);
  font-size: 18px;
  color: var(--color-white);
  &:hover {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }
}

.btn.primary-medium {
  height: 55px;
  background-color: var(--color-primary);
  font-size: 18px;
  color: var(--color-white);
  &:hover {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }
  @media (max-width: (576px)) {
    display: none;
  }
}

.btn.primary-medium--mobile {
  // display: none;
  height: 80px;
  width: 350px;
  background-color: var(--color-primary);
  font-size: 18px;
  color: var(--color-white);
  &:hover {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }
  // @media (max-width: (576px)) {
  //   display: block;
  // }
}

.btn.menu {
  height: 60px;
  padding-left: 40px;
  padding-right: 40px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #3b3b3b;
  border: 1px solid #c4c2c2;
}

.btn.transparent {
  width: 168px;
  height: 55px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border: 2px var(--color-white) solid;
  &:hover {
    background-color: var(--color-black);
    border-color: var(--color-black);
  }
}
