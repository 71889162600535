// SF-Pro-Display
@font-face {
  font-family: 'SF Pro Display';
  src: local('SF-Pro-Display-Thin'),
    url('../assets/Fonts/SF-Pro-Display-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF-Pro-Display-Light'),
    url('../assets/Fonts/SF-Pro-Display-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF-Pro-Display-Regular'),
    url('../assets/Fonts/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF-Pro-Display-Medium'),
    url('../assets/Fonts/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF-Pro-Display-Semibold'),
    url('../assets/Fonts/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF-Pro-Display-Bold'),
    url('../assets/Fonts/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: local('SF-Pro-Display-Black'),
    url('../assets/Fonts/SF-Pro-Display-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
