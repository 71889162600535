.faq {
  padding-top: 100px;
  padding-bottom: 100px;
  @media (max-width: 576px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__title {
    margin-bottom: 20px;
    @media (max-width: 576px) {
      margin-bottom: 10px;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 300;
    @media (max-width: 576px) {
      margin-bottom: 20px;
    }

    &-strong {
      font-weight: 600;
    }
  }
}
