:root {
  --color-black: #000000;
  --color-white: #ffffff;
  --color-primary: #1f5bff;
  --color-active: #0032b4;
  --color-background: #fefefe;
  --color-background-gray: #f6f6f6;
  --color-accordion-bg: #e5f5ff;

  --color-text: #1c1c1c;
  --color-text-light: #3b3b3b;
  --color-text-gray: #7a7a7e;
  --color-text--highlighted: #ff8562;

  --color-main: #9d8df1;
  --color-main-light: #e8e3ff;
}
