html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 400;
  background-color: var(--color-white);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

.title-main {
  font-size: 52px;
  font-weight: 700;
  line-height: 65px;
  color: var(--color-text);
  @media (max-width: 576px) {
    font-size: 30px;
    line-height: 35px;
  }
}

.no-scroll {
  overflow: hidden;
}
