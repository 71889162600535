.plan {
  display: flex;
  align-items: center;
  padding-top: 65px;
  padding-bottom: 85px;
  background-image: linear-gradient(324deg, rgba(89, 0, 255, 1) 22%, rgba(115, 0, 161, 1) 76%);

  &__title {
    font-size: 42px;
    line-height: 1.1;
    font-weight: 700;
    color: var(--color-white);
    margin-bottom: 30px;
    @media (max-width: (576px)) {
      font-size: 30px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    @media (max-width: (576px)) {
      margin-bottom: 30px;
    }

    &-item {
      width: 260px;
      height: 60px;
      padding-left: 15px;
      display: flex;
      align-items: center;
      gap: 15px;
      background-color: #44039d;
      border-radius: 15px;
      &:nth-child(2) {
        margin-left: 50px;
      }

      &--img {
        width: 35px;
        height: 35px;
        img {
          height: 100%;
        }
      }

      &--descr {
        font-size: 18px;
        line-height: 1.15;
        font-weight: 500;
        color: var(--color-white);
      }
    }
  }
}
